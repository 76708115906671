import React from 'react';
import PropTypes from 'prop-types';
import { PageName } from '@components/PageHeader/PageHeader.styled';
import CaseStudyHeadline from './Heading.styled';

export const Heading = ({ name, headline }) => {
  return (
    <>
      <PageName>{name}</PageName>
      <CaseStudyHeadline>{headline}</CaseStudyHeadline>
    </>
  );
};

Heading.propTypes = {
  name: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};
