import React from 'react';
import PropTypes from 'prop-types';
import { AchievementContainer, AchievementTitle, AchievementContent } from './Achievement.styled';

export const Achievement = ({ className, score, name }) => {
  return (
    <AchievementContainer className={className}>
      <AchievementTitle>{score}</AchievementTitle>
      <AchievementContent>{name}</AchievementContent>
    </AchievementContainer>
  );
};

Achievement.propTypes = {
  className: PropTypes.string,
  score: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

Achievement.defaultProps = {
  className: '',
};
