import React from 'react';
import PropTypes from 'prop-types';
import { Flags } from '@fragments';
import { getLogo } from '@helpers/methods';
import { AsideWrapper, AsideLogo, AsideTitle, AsideSubtitle } from './SectionAside.styled';

export const SectionAside = ({ className, name, title, subtitle, countries }) => {
  const LogoComponent = getLogo(name);

  return (
    <AsideWrapper className={className}>
      <AsideLogo>
        <LogoComponent
          config={{
            width: 150,
          }}
        />
      </AsideLogo>
      <AsideTitle>{title}</AsideTitle>
      <AsideSubtitle>{subtitle}</AsideSubtitle>
      <Flags flags={countries} />
    </AsideWrapper>
  );
};

SectionAside.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
};
