import styled from 'styled-components';
import { spaceDt, spaceMb, screenMax, getTypography, getFontSet } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const AsideWrapper = styled.div`
  width: ${toVW(280, 'desktop')};
  display: flex;
  flex-direction: column;
  margin: ${spaceDt(3)};

  ${screenMax('lg')} {
    width: ${toVW(152, 'mobile')};
    margin: 0;
  }
`;
const AsideLogo = styled.div`
  margin-bottom: ${spaceDt(18)};

  ${screenMax('lg')} {
    margin-bottom: 0;
  }
`;

const AsideTitle = styled.h4`
  margin: 0;
  ${getFontSet('heading-4', 'desktop', 'desktop')};

  ${screenMax('lg')} {
    ${getFontSet('heading-3', 'mobile', 'mobile')};
  }
`;

const AsideSubtitle = styled.p`
  margin: ${spaceDt(2.5)} 0;
  ${getTypography('body-2')};

  ${screenMax('lg')} {
    margin: ${spaceMb(1)} 0 ${spaceMb(2)} 0;
  }
`;

export { AsideWrapper, AsideLogo, AsideTitle, AsideSubtitle };
