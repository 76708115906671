import styled from 'styled-components';
import { spaceMb, screenMax } from '@helpers/styles';
import { PageHeadline } from '@components/PageHeader/PageHeader.styled';

const CaseStudyHeadline = styled(PageHeadline)`
  ${screenMax('lg')} {
    padding-left: ${spaceMb(2)};
  }
`;

export default CaseStudyHeadline;
