import styled from 'styled-components';
import { RowBlock, SlideShow, TagGroup } from '@fragments';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { getTypography, spaceMb, spaceDt, screenMax, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { SectionAside } from './components';

export const ContactFormHeading = styled(ContactFormHeader)`
  ${getTypography('heading-3')};
`;

export const CaseStudyTagGroup = styled(TagGroup)`
  margin: 0;
  margin-bottom: ${spaceDt(36)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(10)};
  }
`;

export const CaseStudyRowBlock = styled(RowBlock)`
  margin-bottom: ${spaceDt(15)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(10)};
  }
`;

export const SectionHeyJobs = styled(SectionAside)`
  ${screenMin('lg')} {
    margin-left: ${spaceDt(16)};
  }
`;

export const CaseStudySliderImage = styled(SlideShow)`
  margin-bottom: ${spaceDt(30)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(10)};
  }
`;

export const SectionWrapper = styled.div`
  position: relative;

  ${screenMin('lg')} {
    padding-top: ${toVW(150, 'desktop')};
    margin-top: ${toVW(-150, 'desktop')};
  }
`;
