import React from 'react';
import styled from 'styled-components';
import { Container, Link, Vector } from '@fragments';
import { spaceMb, spaceDt, getTypography, screenMax, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const ReviewContainer = styled(Container)`
  margin: ${spaceDt(10)} 0 ${spaceDt(24)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(10)};
  }
`;

export const ReviewButtons = styled.div`
  margin-top: ${spaceDt(4)};
  display: flex;
  align-self: center;

  ${screenMin('lg')} {
    width: ${toVW(900, 'desktop')};
    justify-content: space-between;
  }

  ${screenMax('lg')} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;
export const ReviewButton = styled(Link)`
  border: 0;
  outline: 0;
  background: none;
  display: flex;
  align-items: center;
  cursor: Pointer;
  margin: 0 ${spaceDt(5)};

  ${screenMin('lg')} {
    width: 50%;
  }

  ${screenMax('lg')} {
    width: 70%;
    padding: ${spaceMb(2)} 0;
    justify-content: center;
  }

  ${getTypography('heading-3')};
`;

export const ReviewButtonPrev = styled(ReviewButton)`
  ${screenMax('lg')} {
    margin-right: ${spaceMb(5.5)};
  }
`;

export const ReviewButtonNext = styled(ReviewButton)`
  ${screenMax('lg')} {
    margin-left: ${spaceMb(6)};
  }
`;

export const ReviewButtonArrow = styled((props) => <Vector {...props} />)`
  width: ${toVW(28, 'desktop')};
  height: ${toVW(14, 'desktop')};
  backface-visibility: hidden;
  flex-shrink: 0;

  ${screenMax('lg')} {
    width: ${toVW(16, 'mobile')};
    height: ${toVW(8, 'mobile')};
  }
`;
export const ArrowPrev = styled(ReviewButtonArrow)`
  transform: rotate(90deg);
  margin-right: ${spaceDt(6)};

  ${screenMax('lg')} {
    margin-right: ${spaceMb(2.5)};
  }
`;

export const ArrowNext = styled(ReviewButtonArrow)`
  transform: rotate(-90deg);
  margin-left: ${spaceDt(6)};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(2.5)};
  }
`;
