import PropTypes from 'prop-types';

export const TYPE_REVIEW_IMG = PropTypes.exact({
  url: PropTypes.string,
  alt: PropTypes.string,
  description: PropTypes.string,
});

export const TYPE_REVIEW_LINK = PropTypes.shape({
  to: PropTypes.string,
  title: PropTypes.string,
});
