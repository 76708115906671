import { Container } from '@fragments';
import styled from 'styled-components';
import { color, spaceMb, spaceDt, getTypography, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import Img from 'gatsby-image';

export const ReviewContainer = styled(Container)`
  margin: 0;
  padding: ${spaceDt(5)} ${spaceDt(17)} ${spaceDt(5)} ${spaceDt(14)};
  flex-direction: column;

  ${screenMax('lg')} {
    padding: 0 ${spaceMb(2)};
  }
`;
export const ReviewHeader = styled.div`
  display: flex;
  align-items: center;

  ${screenMax('lg')} {
    flex-direction: column;
  }
`;

export const ReviewHeaderImage = styled(Img)`
  width: ${toVW(131, 'desktop')};
  height: ${toVW(131, 'desktop')};
  border-radius: 50%;

  ${screenMax('lg')} {
    width: ${toVW(56, 'mobile')};
    height: ${toVW(56, 'mobile')};
    margin-bottom: ${spaceMb(1)};
  }
`;

export const ReviewHeaderDescription = styled.h4`
  padding-left: ${spaceDt(6)};

  ${getTypography('heading-4')};
`;

export const ReviewTitle = styled.h3`
  padding: 0 ${spaceDt(8)};

  ${screenMax('lg')} {
    padding: 0;
    margin-bottom: ${spaceMb(2)};
    text-align: center;
  }

  ${getTypography('heading-3')};
`;

export const ReviewContent = styled.h4`
  padding-left: ${spaceDt(22)};

  ${screenMax('lg')} {
    padding: 0;
    text-align: center;
  }

  ${getTypography('heading-4')};
  color: ${color.text.secondary};
`;
