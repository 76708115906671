import styled from 'styled-components';
import { ClutchWhite } from '@logos';
import { color, spaceMb, spaceDt, screenMax, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const WidgetClutch = styled.div`
  display: flex;
  align-items: center;
`;

const WidgetLogo = styled(ClutchWhite)`
  display: block;
  width: ${toVW(124, 'desktop')};
  height: ${toVW(124, 'desktop')};
  margin-right: ${spaceDt(5)};
  border-radius: 50%;

  ${screenMax('lg')} {
    margin: 0 ${spaceMb(1)};
    margin-top: ${spaceMb(-1)};
    width: ${toVW(56, 'mobile')};
    height: ${toVW(56, 'mobile')};
  }
`;

const Reviews = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMax('lg')} {
    align-items: center;
  }
`;
const ReviewsName = styled.div``;
const ReviewsStars = styled.div`
  margin: ${spaceDt(0.5)} 0;
  display: flex;
`;

const ReviewsDescription = styled.div`
  color: ${color.text.secondary};

  ${getTypography('body-5')};
  letter-spacing: 0.2vw;
`;

export { WidgetLogo, Reviews, ReviewsName, ReviewsStars, WidgetClutch, ReviewsDescription };
