import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { CardCase, Flags, TagGroup } from '@fragments';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { useStateScreenMobile } from '@helpers/hooks';
import { getLogo } from '@helpers/methods';
import { SectionWrapper, SectionHeadline, SectionCards } from './RelatedCaseStudies.styled';

export const RelatedCaseStudies = ({ currentCodename, relatedCodenames }) => {
  const {
    allCaseStudiesJson: { nodes: caseStudyNodes },
  } = useStaticQuery(graphql`
    query {
      allCaseStudiesJson {
        nodes {
          id
          meta {
            thumbnail {
              body
              svgId
              title
            }
            slug
            codename
            countries
            tags
          }
        }
      }
    }
  `);

  const relatedCaseStudies = useMemo(() => {
    const relatedArray = caseStudyNodes.filter(
      (node) => relatedCodenames?.includes(node.meta.codename) && node.meta.codename !== currentCodename && node
    );
    const fallbackArray = caseStudyNodes.slice(0, 5).filter((node) => node.meta.codename !== currentCodename && node);

    return relatedArray.length > 0 ? relatedArray : fallbackArray;
  }, [caseStudyNodes]);

  const [sliderRef] = useKeenSlider({
    slidesPerView: useStateScreenMobile() ? 1.8 : 3,
    mode: 'free',
    dragSpeed: 1,
    duration: 650,
    spacing: 16,
  });

  return (
    <SectionWrapper>
      <SectionHeadline>Other Work</SectionHeadline>
      <SectionCards ref={sliderRef} className="keen-slider">
        {relatedCaseStudies.map(({ id, meta: { slug, thumbnail, countries, tags } }) => {
          const LogoComponent = getLogo(thumbnail?.svgId);
          return (
            <CardCase
              key={id}
              className="keen-slider__slide"
              to={`/case-studies/${slug}`}
              logo={<LogoComponent />}
              title={thumbnail.title}
              body={thumbnail.body}
              slotFlags={<Flags flags={countries} />}
              slotTags={<TagGroup size="small" tags={tags.slice(0, 5)} />}
            />
          );
        })}
      </SectionCards>
    </SectionWrapper>
  );
};

RelatedCaseStudies.propTypes = {
  currentCodename: PropTypes.string.isRequired,
  relatedCodenames: PropTypes.arrayOf(PropTypes.string),
};

RelatedCaseStudies.defaultProps = {
  relatedCodenames: null,
};
