import styled from 'styled-components';
import { screenMin, spaceDt, spaceMb } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { SectionWrapper } from '../CaseStudy.styled';

export const ChallengeSectionWrapper = styled(SectionWrapper)`
  padding-top: ${toVW(150, 'mobile')};
  margin-bottom: ${spaceMb(10)};

  ${screenMin('lg')} {
    padding-top: ${toVW(150, 'desktop')};
    margin-bottom: ${spaceDt(40)};
  }
`;
