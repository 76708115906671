import React from 'react';
import PropTypes from 'prop-types';

import { SectionSplit } from '@fragments';
import { SectionSolutionLines } from '../components';
import { CaseStudyRowBlock, SectionWrapper } from '../CaseStudy.styled';

export const SectionSolution = ({ id, svgId, name, title, blocks }) => (
  <SectionWrapper id={id}>
    <SectionSolutionLines />
    <SectionSplit vectorName={svgId} name={name} title={title} />
    <CaseStudyRowBlock content={blocks} />
  </SectionWrapper>
);

export default SectionSolution;

SectionSolution.propTypes = {
  id: PropTypes.string.isRequired,
  svgId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
};
