import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_LINK } from '@helpers/types';
import { Heading } from '..';
import { CaseStudyHeaderContainer, BackLink, BackArrow } from './CaseStudyHeader.styled';

export const CaseStudyHeader = ({ backLink: { to, title, label }, companyName, caseStudyTitle }) => (
  <CaseStudyHeaderContainer>
    <BackLink to={to} title={title}>
      <BackArrow name="icon-arrow" />
      {label}
    </BackLink>
    <Heading name={companyName} headline={caseStudyTitle} />
  </CaseStudyHeaderContainer>
);

CaseStudyHeader.propTypes = {
  backLink: TYPE_LINK.isRequired,
  companyName: PropTypes.string.isRequired,
  caseStudyTitle: PropTypes.string.isRequired,
};
