import styled from 'styled-components';
import { spaceMb, getTypography, spaceDt, screenMax, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spaceDt(24.5)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(15)};
  }
`;

const SectionHeadline = styled.h2`
  padding-left: var(--content-padding-left);
  padding-right: var(--content-padding-right);
  margin-bottom: ${spaceMb(2)};
  ${getTypography('heading-1')}

  ${screenMin('lg')} {
    padding-left: var(--content-padding-lg-left);
    padding-right: var(--content-padding-lg-right);
    max-width: ${toVW(920, 'desktop')};
    margin-bottom: ${spaceDt(5)};
  }

  ${screenMax('lg')} {
    padding-left: ${spaceMb(2)};
  }
`;

const SectionCards = styled.div`
  padding-left: var(--content-padding-left);
  padding-right: var(--content-padding-right);
`;

export { SectionWrapper, SectionHeadline, SectionCards };
