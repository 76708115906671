import React from 'react';
import PropTypes from 'prop-types';

import { SectionSplit } from '@fragments';
import { SectionProductLines } from '../components';
import { ProductSectionWrapper } from './SectionProduct.styled';

export const SectionProduct = ({ id, svgId, name, title, content }) => (
  <ProductSectionWrapper id={id}>
    <SectionProductLines />
    <SectionSplit vectorName={svgId} name={name} title={title} content={content} />
  </ProductSectionWrapper>
);

SectionProduct.propTypes = {
  id: PropTypes.string.isRequired,
  svgId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
