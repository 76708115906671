import React from 'react';
import styled from 'styled-components';
import { Container, Link, Vector } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const CaseStudyHeaderContainer = styled(Container)`
  align-items: flex-start;
`;

export const BackLink = styled(Link)`
  color: ${color.accent[2]};
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${spaceDt(7)};

  ${getTypography('body-2')}
`;

export const BackArrow = styled((props) => <Vector {...props} />)`
  width: ${toVW(9, 'mobile')};
  height: ${toVW(7, 'mobile')};
  margin-right: ${spaceMb(0.5)};

  ${screenMin('lg')} {
    width: ${toVW(9, 'desktop')};
    height: ${toVW(7, 'desktop')};
    margin-right: ${spaceDt(1)};
  }
`;
