import styled from 'styled-components';
import { Container } from '@fragments';
import { color, spaceDt, spaceMb, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Achievement } from '../components';

const WidgetWrapper = styled(Container)`
  align-items: center;
  background-color: ${color.accent[1]};

  ${screenMin('lg')} {
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    margin: ${spaceDt(5)} 0;
    height: ${toVW(80, 'desktop')};
  }

  ${screenMax('lg')} {
    padding: 0;
  }
`;

const Achievements = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;

  ${screenMin('lg')} {
    margin-left: ${spaceDt(5)};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(2)};
  }
`;

const ClutchAchievement = styled(Achievement)`
  margin-left: ${spaceMb(4)};

  ${screenMin('lg')} {
    margin-left: ${spaceDt(10)};
    &:first-of-type {
      margin-left: ${spaceDt(15)};
    }
  }
`;

export { WidgetWrapper, Achievements, ClutchAchievement };
