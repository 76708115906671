import styled from 'styled-components';
import { screenMin, spaceMb, spaceDt } from '@helpers/styles';
import { SectionWrapper } from '../CaseStudy.styled';

export const CompanySectionWrapper = styled(SectionWrapper)`
  margin: ${spaceMb(5)} 0;

  ${screenMin('lg')} {
    margin: ${spaceDt(30)} 0;
  }
`;
