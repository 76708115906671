import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TYPE_IMAGE_GATSBY_WEBP } from '@helpers/types';
import { useStateScreenMobile } from '@helpers/hooks';
import { SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormHeading, CaseStudyTagGroup, CaseStudySliderImage } from './CaseStudy.styled';

import {
  CaseStudyHeader,
  SectionClutch,
  SectionTechStack,
  SectionSolution,
  SectionChallenge,
  SectionProduct,
  SectionCompany,
} from './sections';
import { RelatedCaseStudies, AdjacentCaseStudies, SectionAside, CaseStudyNav, SectionSliderLines } from './components';

const ContactFormHeadline = () => (
  <ContactFormHeading>
    {useStateScreenMobile() ? 'Contact us' : 'Tell us how we can help you change the world'}
  </ContactFormHeading>
);

const getNavLinks = (contentObj) => {
  return Object.entries(contentObj).reduce((validSections, entry) => {
    if (!entry[1]) return validSections;

    const { id, link } = entry[1];
    const label = link?.label || id?.slice(1).toUpperCase() + id?.substring(1);

    id &&
      link &&
      validSections.push({
        to: `#${id}`,
        label,
        title: `Go to ${label}`,
      });

    return validSections;
  }, []);
};

const CaseStudy = ({
  pageContext: {
    data: { content, meta, review },
    settings: { linkUp },
    switch: { prev, next },
    slides,
    reviewThumbs,
  },
}) => {
  const navLinks = useMemo(() => content && getNavLinks(content), [content]);
  const { company, product, challenge, solution, techStack, achievements } = content;

  return (
    <>
      {Boolean(meta.seo) && (
        <SEO title={meta.seo.title || meta.title} description={meta.seo.description || meta?.thumbnail?.body} />
      )}

      {Boolean(linkUp) && <CaseStudyHeader backLink={linkUp} companyName={meta.company} caseStudyTitle={meta.title} />}

      {Boolean(navLinks) && navLinks.length > 0 && <CaseStudyNav navLinks={navLinks} />}

      {Boolean(company) && (
        <SectionCompany
          id={company.id}
          svgId={company.svgId}
          name={company.name}
          title={company.title}
          content={company.content}
          contentAside={
            <SectionAside
              name={meta.thumbnail?.svgId}
              title={meta.type}
              subtitle={meta.timeline}
              countries={meta.countries}
            />
          }
        />
      )}

      {Boolean(product) && (
        <SectionProduct
          id={product.id}
          svgId={product.svgId}
          name={product.name}
          title={product.title}
          content={product.content}
        />
      )}

      {Boolean(slides.length > 0) && (
        <>
          <SectionSliderLines />
          <CaseStudySliderImage slides={slides} />
        </>
      )}

      {Boolean(challenge) && (
        <SectionChallenge
          id={challenge.id}
          svgId={challenge.svgId}
          name={challenge.name}
          title={challenge.title}
          content={challenge.content}
        />
      )}

      {Boolean(solution) && (
        <SectionSolution
          id={solution.id}
          svgId={solution.svgId}
          name={solution.name}
          title={solution.title}
          blocks={solution.blocks}
        />
      )}

      {Boolean(techStack) && (
        <SectionTechStack id={techStack.id} svgId={techStack.svgId} name={techStack.name} title={techStack.title} />
      )}

      {Boolean(meta?.tags?.length) && <CaseStudyTagGroup tags={meta.tags} />}

      <SectionClutch achievements={achievements} />

      {Boolean(reviewThumbs.length) && (
        <AdjacentCaseStudies thumb={reviewThumbs[0]} review={review} prev={prev} next={next} />
      )}

      <RelatedCaseStudies currentCodename={meta.codename} relatedCodenames={meta.related_codenames} />

      <ContactForm headline={<ContactFormHeadline />} />
    </>
  );
};

export default CaseStudy;

const TYPE_CONTENT_SECTION = PropTypes.shape({
  id: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string,
  }),
  svgId: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
});

const TYPE_DATA_CONTENT = PropTypes.shape({
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  company: TYPE_CONTENT_SECTION,
  product: TYPE_CONTENT_SECTION,
  challenge: TYPE_CONTENT_SECTION,
  solution: TYPE_CONTENT_SECTION,
  techStack: TYPE_CONTENT_SECTION,
});

const TYPE_DATA_GALLERY = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  })
);

const TYPE_DATA = PropTypes.shape({
  content: TYPE_DATA_CONTENT,
  gallery: TYPE_DATA_GALLERY,
  meta: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(PropTypes.string),
      PropTypes.bool,
    ])
  ),
  review: PropTypes.objectOf(PropTypes.string),
});

const TYPE_REVIEWS = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.shape({
      alt: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
    }),
  })
);

const TYPE_SETTINGS = PropTypes.shape({
  linkUp: PropTypes.objectOf(PropTypes.string),
});

const TYPE_NAV_LINK = PropTypes.objectOf(PropTypes.objectOf(PropTypes.string));

CaseStudy.propTypes = {
  pageContext: PropTypes.shape({
    data: TYPE_DATA,
    reviews: TYPE_REVIEWS,
    settings: TYPE_SETTINGS,
    switch: TYPE_NAV_LINK,
    slides: PropTypes.arrayOf(TYPE_IMAGE_GATSBY_WEBP),
    reviewThumbs: PropTypes.arrayOf(TYPE_IMAGE_GATSBY_WEBP),
  }).isRequired,
};
