import React from 'react';
import { Strings } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';

export const SectionCompanyLines = () =>
  useStateScreenMobile() ? (
    <Strings name="case-study-company--mobile" height={91} top={0} mobile />
  ) : (
    <Strings name="case-study-company" height={982} top={-30} />
  );

export const SectionProductLines = () =>
  useStateScreenMobile() ? <Strings name="case-study-product--mobile" height={184} top={-250} mobile /> : null;

export const SectionSliderLines = () =>
  useStateScreenMobile() ? <Strings name="case-study-slider--mobile" height={95} top={-40} mobile /> : null;

export const SectionChallengeLines = () =>
  useStateScreenMobile() ? (
    <>
      <Strings name="case-study-challenge--mobile-1" height={92} top={-50} mobile />
      <Strings name="case-study-challenge--mobile-2" height={327} top={70} mobile />
    </>
  ) : (
    <Strings name="case-study-challenge" height={355} top={-50} />
  );

export const SectionSolutionLines = () =>
  useStateScreenMobile() ? null : <Strings name="case-study-solution" height={564} top={10} />;

export const SectionTechStackLines = () =>
  useStateScreenMobile() ? (
    <>
      <Strings name="case-study-tech-stack--mobile-1" height={599} top={-570} mobile />
      <Strings name="case-study-tech-stack--mobile-2" height={431} top={170} mobile />
    </>
  ) : (
    <Strings name="case-study-tech-stack" height={1164} top={-90} />
  );
