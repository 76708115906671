import React from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { TYPE_LINK } from '@helpers/types';
import { CaseStudyNavContainer, CaseStudyNavLinkList, CaseStudyNavLink } from './CaseStudyNav.styled';

export const CaseStudyNav = ({ navLinks }) => {
  const getAnchorLinks = navLinks.map((link) => (
    <CaseStudyNavLink key={link.to} onClick={() => scrollTo(link.to)}>
      {link.label}
    </CaseStudyNavLink>
  ));

  return (
    <CaseStudyNavContainer>
      <CaseStudyNavLinkList>{getAnchorLinks}</CaseStudyNavLinkList>
    </CaseStudyNavContainer>
  );
};

CaseStudyNav.propTypes = {
  navLinks: PropTypes.arrayOf(TYPE_LINK).isRequired,
};
