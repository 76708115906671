import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_IMAGE_GATSBY_WEBP } from '@helpers/types';
import { Review } from '../index';
import { TYPE_REVIEW_LINK } from './AdjacentCaseStudies.types';
import {
  ReviewContainer,
  ReviewButtons,
  ReviewButtonPrev,
  ReviewButtonNext,
  ArrowPrev,
  ArrowNext,
} from './AdjacentCaseStudies.styled';

export const AdjacentCaseStudies = ({ thumb, review: { titleThumb, title, content }, labels, prev, next }) => (
  <ReviewContainer>
    <Review thumb={thumb} titleThumb={titleThumb} title={title} content={content} />

    <ReviewButtons>
      <ReviewButtonPrev to={prev.to} title={`Go to ${prev.title} Case study`}>
        <ArrowPrev name="icon-chevron" />
        {labels.prev}
      </ReviewButtonPrev>
      <ReviewButtonNext to={next.to} title={`Go to ${next.title} Case study`}>
        {labels.next}
        <ArrowNext name="icon-chevron" />
      </ReviewButtonNext>
    </ReviewButtons>
  </ReviewContainer>
);

AdjacentCaseStudies.propTypes = {
  thumb: TYPE_IMAGE_GATSBY_WEBP.isRequired,
  review: PropTypes.shape({
    titleThumb: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  labels: PropTypes.shape({
    prev: PropTypes.string,
    next: PropTypes.string,
  }),
  prev: TYPE_REVIEW_LINK.isRequired,
  next: TYPE_REVIEW_LINK.isRequired,
};

AdjacentCaseStudies.defaultProps = {
  labels: {
    prev: 'Previous',
    next: 'Next',
  },
};
