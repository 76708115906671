import React from 'react';
import PropTypes from 'prop-types';
import { Rating } from '@fragments';
import {
  WidgetLogo,
  Reviews,
  ReviewsName,
  ReviewsStars,
  WidgetClutch,
  ReviewsDescription,
} from './ClutchWidget.styled';

export const ClutchWidget = ({ name, reviewsDescription, rating }) => {
  return (
    <WidgetClutch>
      <WidgetLogo />
      <Reviews>
        <ReviewsName>{name}</ReviewsName>
        <ReviewsStars>
          <Rating rating={rating} />
        </ReviewsStars>
        <ReviewsDescription>{reviewsDescription}</ReviewsDescription>
      </Reviews>
    </WidgetClutch>
  );
};

ClutchWidget.propTypes = {
  name: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  reviewsDescription: PropTypes.string.isRequired,
};
