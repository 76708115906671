import styled from 'styled-components';
import { color, spaceMb, spaceDt, screenMin, getFontSet } from '@helpers/styles';

export const CaseStudyNavContainer = styled.nav`
  height: auto;
  margin: ${spaceMb(4)} 0;
  padding: ${spaceMb(0.5)};
  background-color: ${color.accent[1]};
  z-index: 2;
  display: flex;

  ${screenMin('lg')} {
    height: var(--the-header-height);
    position: sticky;
    top: 0;
    margin: ${spaceDt(8)} 0;
    padding: ${spaceDt(2.5)} ${spaceDt(16.5)};
  }
`;

export const CaseStudyNavLinkList = styled.ul`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const CaseStudyNavLink = styled.li`
  text-decoration: underline;
  cursor: pointer;
  ${getFontSet('heading-4', 'mobile', 'mobile')}
  margin: ${spaceMb(1.5)} ${spaceMb(2)};

  ${screenMin('lg')} {
    margin: 0 ${spaceDt(6)};
    ${getFontSet('body-1', 'desktop', 'desktop')}
  }
`;
