import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';

import { SectionSplit } from '@fragments';
import { SectionCompanyLines } from '../components';
import { CompanySectionWrapper } from './SectionCompany.styled';

export const SectionCompany = ({ id, svgId, name, title, content, contentAside }) => (
  <CompanySectionWrapper id={id}>
    <SectionCompanyLines />
    <SectionSplit vectorName={svgId} name={name} title={title} content={content} contentAside={contentAside} />
  </CompanySectionWrapper>
);

SectionCompany.propTypes = {
  id: PropTypes.string.isRequired,
  svgId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentAside: TYPE_COMPONENT.isRequired,
};
