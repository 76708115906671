import styled from 'styled-components';
import { screenMin, spaceMb, spaceDt } from '@helpers/styles';
import { SectionWrapper } from '../CaseStudy.styled';

export const ProductSectionWrapper = styled(SectionWrapper)`
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(5)};
  }
`;
