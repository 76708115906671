import styled from 'styled-components';
import { spaceDt, getTypography, screenMax } from '@helpers/styles';

const AchievementContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${screenMax('lg')} {
    flex-direction: column-reverse;
  }
`;

const AchievementTitle = styled.h3`
  margin: 0;

  ${getTypography('heading-3')};
`;

const AchievementContent = styled.p`
  margin-left: ${spaceDt(2.5)};

  ${screenMax('lg')} {
    margin: 0;
  }
`;

export { AchievementContainer, AchievementTitle, AchievementContent };
