import React from 'react';
import PropTypes from 'prop-types';

import { SectionSplit } from '@fragments';
import { SectionTechStackLines } from '../components';
import { SectionWrapper } from '../CaseStudy.styled';

export const SectionTechStack = ({ id, svgId, name, title }) => (
  <SectionWrapper id={id}>
    <SectionTechStackLines />
    <SectionSplit vectorName={svgId} name={name} title={title} />
  </SectionWrapper>
);

SectionTechStack.propTypes = {
  id: PropTypes.string.isRequired,
  svgId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
