import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_IMAGE_GATSBY_WEBP } from '@helpers/types';
import {
  ReviewContainer,
  ReviewHeader,
  ReviewHeaderImage,
  ReviewHeaderDescription,
  ReviewTitle,
  ReviewContent,
} from './Review.styled';

export const Review = ({ thumb: { fluid, name }, titleThumb, title, content }) => (
  <ReviewContainer>
    <ReviewHeader>
      <ReviewHeaderImage fluid={fluid} alt={name} />
      <ReviewHeaderDescription>{titleThumb}</ReviewHeaderDescription>
    </ReviewHeader>
    <ReviewTitle>{title}</ReviewTitle>
    <ReviewContent>{content}</ReviewContent>
  </ReviewContainer>
);

Review.propTypes = {
  thumb: TYPE_IMAGE_GATSBY_WEBP.isRequired,
  titleThumb: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
