import React from 'react';
import PropTypes from 'prop-types';
import { SectionSplit } from '@fragments';
import { SectionChallengeLines } from '../components';
import { ChallengeSectionWrapper } from './SectionChallenge.styled';

export const SectionChallenge = ({ id, svgId, name, title, content }) => (
  <ChallengeSectionWrapper id={id}>
    <SectionChallengeLines />
    <SectionSplit vectorName={svgId} name={name} title={title} content={content} />
  </ChallengeSectionWrapper>
);

SectionChallenge.propTypes = {
  id: PropTypes.string.isRequired,
  svgId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
