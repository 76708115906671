import React from 'react';
import PropTypes from 'prop-types';
import { ClutchWidget } from '../components';
import { WidgetWrapper, Achievements, ClutchAchievement } from './SectionClutch.styled';

export const SectionClutch = ({ achievements }) => {
  if (achievements?.length > 2) throw new Error('The max amount of achievements is 2.');

  return (
    <WidgetWrapper>
      <ClutchWidget name="CLUTCH" rating="4.8" reviewsDescription="15 REVIEWS" />
      {Boolean(achievements) && (
        <Achievements>
          {achievements.map((achievement, index) => (
            <ClutchAchievement key={index} score={achievement.header} name={achievement.title} />
          ))}
        </Achievements>
      )}
    </WidgetWrapper>
  );
};

SectionClutch.propTypes = {
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

SectionClutch.defaultProps = {
  achievements: null,
};
